import { base64ToArrayBuffer, addPdfJs } from '@utils/common-methods/common'

const uploadIdMixin = {
  mounted() {
    addPdfJs()
  },
  methods: {
    convertFileToArrayBuffer(file, callback) {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result)
      }
      reader.readAsArrayBuffer(file)
    },
    convertPdfToImageThenStore(arrayBuffer, callback) {
      const pdfjsLib = window['pdfjs-dist/build/pdf']
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js'

      const typedArray = new Uint8Array(arrayBuffer)
      const loadingTask = pdfjsLib.getDocument(typedArray)

      loadingTask.promise.then((pdf) => {
        // Only allow one page as the government Id
        const pageNumber = 1
        pdf.getPage(pageNumber).then((page) => {
          const scale = 1.5
          const viewport = page.getViewport({ scale: scale })

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          }
          const renderTask = page.render(renderContext)
          renderTask.promise.then(() => {
            let base64 = canvas.toDataURL()
            base64 = base64.split(',')[1].trim()
            const newArrayBuffer = base64ToArrayBuffer(base64)
            callback(newArrayBuffer)
          })
        })
      }, (reason) => {
        console.error(reason)
      })
    },
  }
}
export default uploadIdMixin
