<template>
  <div class="verify-government-id">
    <NavBack/>
    <div class="page-header sticky-header has-text-centered is-relative is-flex is-justify-content-center is-align-items-center">
      <div class="go-back" @click="goBack">
        <img src="@assets/icons/arrow-left.svg" alt="go-back">
      </div>
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toDashboard">
    </div>
    <div class="page-title has-text-weight-semibold has-text-centered">Verify your account</div>
    <div class="white-box-wrap">
      <div class="white-box has-text-centered has-background-white">
        <img :src="previewUrl" alt="government-id" class="government-id">
        <div class="section-title has-text-weight-semibold pt-3">Is your ID easy to read?</div>
        <div class="text">Please make sure that the text is clear and your entire card is visible.</div>
        <b-button type="is-blue" @click="submitGovernmentId" :loading="isLoading">Yeup, looks good!</b-button>
        <div class="retake-button py-3">
          <span class="is-relative is-inline-block px-3 py-2 has-text-lavender has-text-weight-semibold">Retake
            <input type="file" name="government_id_image" @change="changeId" accept="image/*, application/pdf">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import { initIndexedDB } from '@utils/common-methods/initIndexedDB'
import { uploadGovernmentID } from '@api/profile'
import uploadIdMixin from '@utils/mixins/government-id-upload'

export default {
  mixins: [uploadIdMixin],
  components: {
    NavBack,
  },
  data() {
    return {
      imgFile: null,
      previewUrl: '',
      isLoading: false,
    }
  },
  computed: {
    nextStepRoute() {
      const returnUrl = this.$route.query.returnUrl
      const route = '/investorProfile/governmentId/success'
      return returnUrl ? `${route}?returnUrl=${returnUrl}` : route
    },
  },
  created() {
    this.getFile()
  },
  beforeDestroy() {
    const URL = window.URL || window.webkitURL
    URL.revokeObjectURL(this.previewUrl)
  },
  methods: {
    getFile() {
      const objectStore = 'govermentId'
      initIndexedDB('memberProfile', objectStore, (db) => {
        const transaction = db.transaction([objectStore])
        const request = transaction.objectStore(objectStore).get('image')
        request.onsuccess = () => {
          this.convertFile(request)
        }
      })
    },
    convertFile(request) {
      const URL = window.URL || window.webkitURL

      if (this.previewUrl) URL.revokeObjectURL(this.previewUrl)

      const blob = new Blob([request.result], { type: 'image/jpeg' })
      this.imgFile = new File([blob], 'government-ID.jpeg', { type: 'image/jpeg' })
      this.previewUrl = URL.createObjectURL(this.imgFile)
    },
    changeId(event) {
      const fileType = event.target.files[0].type

      this.convertFileToArrayBuffer(event.target.files[0], (arrayBuffer) => {
        if (fileType === 'application/pdf') {
          this.convertPdfToImageThenStore(arrayBuffer, (newArrayBuffer) => {
            this.storeFileInIndexedDbAndRenderImage(newArrayBuffer)
          })
        } else {
          this.storeFileInIndexedDbAndRenderImage(arrayBuffer)
        }
      })
    },
    storeFileInIndexedDbAndRenderImage(arrayBuffer) {
      const objectStore = 'govermentId'
      initIndexedDB('memberProfile', objectStore, (db) => {
        const transaction = db.transaction([objectStore], 'readwrite')
        transaction.objectStore(objectStore).put(arrayBuffer, 'image').onsuccess = () => {
          const request = transaction.objectStore(objectStore).get('image')
          request.onsuccess = () => {
            this.convertFile(request)
          }
        }
      })
    },
    submitGovernmentId() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('file', this.imgFile)
      uploadGovernmentID(formData).then((result) => {
        this.isLoading = false
        if (!result.success) return
        this.$router.push(this.nextStepRoute)
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    toDashboard() {
      this.$router.push('/dashboard')
    },
  },
}
</script>
<style lang="scss" scoped>
.verify-government-id {
  padding-bottom: 60px;
  .page-header {
    height: 68px;
    .go-back {
      padding: 0 10px;
      position: absolute;
      top: 23px;
      left: 20px;
    }
    .logo {
      width: 63px;
    }
  }
  .page-title {
    font-size: 32px;
    line-height: 38px;
  }
  .white-box-wrap {
    padding: 12px 25px 0;
    .white-box {
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      border-radius: 12px;
      padding: 24px 22px 8px;
      font-size: 16px;
      line-height: 19px;
      .government-id {
        width: 100%;
        border-radius: 12px;
      }
      .section-title {
        margin-bottom: 8px;
      }
      .button {
        width: 262px;
        height: 43px;
        border-radius: 12px;
        margin-top: 20px;
      }
      .retake-button {
        input {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style scoped>
.white-box .button >>> span {
  font-weight: bold;
}
</style>
