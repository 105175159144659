/**
 * @param {String} databaseName
 * @param {String} objectStore
 * @param {Function} onSuccess
 */
export const initIndexedDB = (databaseName, objectStore, onSuccess) => {
  const indexedDB = window.indexedDB || window.webkitIndexedDB
  const dbVersion = 1.0

  const request = indexedDB.open(databaseName, dbVersion)
  let db

  const createObjectStore = (dataBase) => {
    dataBase.createObjectStore(objectStore)
  }

  request.onerror = () => {
    console.log('Error creating/accessing IndexedDB database')
  }
  request.onsuccess = () => {
    db = request.result
    db.onerror = () => {
      console.log('Error creating/accessing IndexedDB database')
    }

    if (db.setVersion) {
      if (db.version !== dbVersion) {
        const setVersion = db.setVersion(dbVersion)
        setVersion.onsuccess = () => {
          createObjectStore(db)
          onSuccess(db)
        }
      } else {
        onSuccess(db)
      }
    } else {
      onSuccess(db)
    }
  }
  request.onupgradeneeded = (e) => {
    createObjectStore(e.target.result)
  }
}
