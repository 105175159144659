import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * upload government ID
 * @param {Object} formData
 * @return {Promise}
 */
export const uploadGovernmentID = (formData) => {
  return axios.post(getImbyRoute('/api/v1/investors/profile/government_id_upload'), formData)
}

/**
 * upload SIN
 * @param {Object} formData
 * @return {Promise}
 */
export const uploadSIN = (formData) => {
  return axios.post(getImbyRoute('/api/v1/investors/profile/sin_upload'), formData)
}

/**
 * toggle electronic reception for tax documents
 * @param {Object} formData
 * @return {Promise}
 */
export const toggleElectronicReception = (formData) => {
  return axios.put(getImbyRoute('/api/v1/investors/profile/toggle_electronic_reception'), formData)
}

/**
 * get profile details
 * @return {Promise}
 */
export const getProfileDetails = () => {
  return axios.get(getImbyRoute('/api/v1/investors/profile/details'))
}

/**
 * fetch for attributes to initialize HelloSign
 * @return {Promise}
 */
export const getHelloSignAttributes = () => {
  return axios.get(getImbyRoute('/api/v1/investors/profile/confidentiality_agreement_url'))
}

/**
 * update signing status
 * @return {Promise}
 */
export const updateSignStatus = () => {
  return axios.put(getImbyRoute('/api/v1/investors/profile/confidentiality_signed'))
}

/**
 * fetch confidentiality url of addySign
 * @param {String} nextStepUrl
 * @return {Promise}
 */
export const fetchConfidentialityUrl = (nextStepUrl) => {
  return axios.get(getImbyRoute(`/api/v1/addy_sign/agreements/fetch_confidentiality_url?next_step_url=${nextStepUrl}`))
}

/**
 * register role in Discord
 * @param {String} code
 * @return {Promise}
 */
export const registerRoleOnDiscord = (code) => {
  return axios.post(getImbyRoute('/api/v1/investors/profile/connect_discord'), { code })
}

/**
 * save annual income and net worth
 * @param {Number} annualIncome
 * @param {Number} netWorth
 * @return {Promise}
 */
export const saveWealthInfo = (annualIncome, netWorth) => {
  return axios.post(getImbyRoute('/api/v1/investors/profile/save_wealth_info'), { investor_profile: { annual_income: annualIncome, net_worth: netWorth } })
}

/**
 * get annual income and net worth
 * @return {Promise}
 */
export const getWealthInfo = () => {
  return axios.get(getImbyRoute('/api/v1/investors/profile/fetch_wealth_info'))
}

/**
 * fetch questions and options for the suitability questionnaire
 * @return {Promise}
 */
export const fetchQuestionnaire = () => {
  return axios.get(getImbyRoute('/api/v1/investors/profile/suitability_questionnaire_fetch'))
}

/**
 * save answers from the suitability questionnaire
 * @param {Object} payload
 * @return {Promise}
 */
export const saveQuestionnaire = (payload) => {
  return axios.post(getImbyRoute('/api/v1/investors/profile/suitability_questionnaire_save'), { investor_profile: { suitability_answers: payload } })
}

/**
 * fetch investor's ranking on the wait list
 * @return {Promise}
 */
export const fetchWaitListRanking = () => {
  return axios.get(getImbyRoute('/api/v1/investors/us_waiting_list'))
}
