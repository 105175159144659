<template>
  <div>
    <div class="menu-and-logo is-flex is-justify-content-center is-align-items-center is-relative">
      <img class="menu-icons nav-back" src="@assets/icons/arrow-left.svg" alt="go back" @click="navBack" v-if="hasNavBack">
      <img class="menu-icons" src="@assets/icons/menu.svg" alt="menu" @click="showMenu" v-else>
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toDashboard">
      <div
        v-if="showReferral"
        :class="`referral-icon has-text-weight-bold is-flex is-justify-content-center is-align-items-center ${seenReferralIcon ? '' : 'bouncing'}`"
        :style="`background-color: ${referralIconBackgroundColor};`"
        @click="toReferralDashboard"
      >
        <img src="@assets/icons/gift.svg" alt="gift">
        <div
          class="notice-icon is-flex is-justify-content-center is-align-items-center has-text-white"
          @click.stop="()=>{return false}"
          v-if="!seenReferralIcon || hasNewReferrals"
        >!</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPropertyBaseUrl, getReferralFlags } from '@api/dashboard'
import { updateInvestorFlags } from '@api/common'
import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      seenReferralIcon: true,
      hasNewReferrals: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  created() {
    this.getUrl()
    this.showReferral && this.fetchReferralStatus()
  },
  props: {
    showReferral: {
      type: Boolean,
      default: false
    },
    referralIconTracker: {
      type: String,
      default: ''
    },
    referralIconBackgroundColor: {
      type: String,
      default: '#F0EEF8'
    },
    hasNavBack: {
      type: Boolean,
      default: false
    },
    action: {
      type: Function,
      default: null
    },
  },
  methods: {
    showMenu() {
      this.$store.commit('toggleMenu', true)
    },
    getUrl() {
      getPropertyBaseUrl().then((data) => {
        if (data.success) this.$store.commit('setPropertyBaseUrlOnMenu', data.data.propertiesForSaleUrl)
      })
    },
    toDashboard() {
      this.$router.push('/dashboard')
    },
    fetchReferralStatus() {
      getReferralFlags().then((data) => {
        if (!data.success) return
        this.seenReferralIcon = data.data.referralGiftIconSeenAt
        this.hasNewReferrals = data.data.newReferrals
      })
    },
    toReferralDashboard() {
      updateInvestorFlags({
        referral_gift_icon_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
        referral_page_last_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
      })
      this.referralIconTracker && this.mixpanel.track(this.referralIconTracker)
      this.$router.push('/referralDashboard')
    },
    navBack() {
      if (this.action) this.action()
      else this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  7%   {top: 8px;}
  0%, 14%, 100%  {top: 15px;}
}

.menu-and-logo {
  height: 68px;
  .menu-icons {
    position: absolute;
    top: 27px;
    left: 25px;
    width: 20px;
    &.nav-back {
      width: unset;
    }
  }
  .logo {
    width: 63px;
  }
  .referral-icon {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #F0EEF8;
    &.bouncing {
      animation: bounce 4.6s ease-in-out infinite;
    }
    > img {
      width: 16px;
    }
    .notice-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #3CDBC0;
      position: absolute;
      top: -7.5px;
      right: -15px;
      font-size: 16px;
    }
  }
}
</style>
