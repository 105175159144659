import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=f23cb6a6&scoped=true&"
var script = {}
import style0 from "./Index.vue?vue&type=style&index=0&id=f23cb6a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f23cb6a6",
  null
  
)

export default component.exports