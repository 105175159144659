<template>
  <Teleport to="#desktop-header-left">
    <div class="is-clickable is-flex is-align-items-center" @click="goBack">
      <img src="@assets/icons/arrow-left.svg" alt="go-back" class="mx-4">
      <span class="text-back has-text-weight-semibold">{{ text }}</span>
    </div>
  </Teleport>
</template>
<script>
import Teleport from 'vue2-teleport'

export default {
  components: {
    Teleport,
  },
  props: {
    text: {
      type: String,
      default: 'Back'
    },
    action: {
      type: Function,
      default: null
    }
  },
  methods: {
    goBack() {
      if (this.action) this.action()
      else this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.text-back {
  font-size: 16px;
  color: #2D2926;
}
</style>
