<template>
  <div class="government-id-success">
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="page-title has-text-weight-semibold has-text-centered">Verify your account</div>
    <div class="central-column has-text-right">
      <div class="white-box has-text-centered has-background-white">
        <div class="has-text-weight-semibold mb-3">You’re all set!</div>
        <div>Thanks for your patience, {{firstName}}!</div>
        <div>Give us some time to review the verification of your account.</div>
        <img src="@assets/images/profile/success.svg" alt="success" class="success-background">
        <div>Once your account has been verified, we will email you. Feel free to come back to your profile to check out the status.</div>
      </div>
      <button class="button is-medium-height is-primary is-rounded has-text-weight-semibold" @click="onFinish">Finish</button>
    </div>
  </div>
</template>
<script>
import { initIndexedDB } from '@utils/common-methods/initIndexedDB'
import { embeddedInIos } from '@utils/common-methods/common'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'

export default {
  components: {
    MenuAndLogo,
  },
  computed: {
    firstName() {
      const { firstName } = JSON.parse(localStorage.getItem('investor'))
      return firstName
    },
  },
  methods: {
    onFinish() {
      this.deleteGovernmentIdInIDB()
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)

      const returnUrl = this.$route.query.returnUrl
      const route = returnUrl ? decodeURIComponent(returnUrl) : '/investorProfile'
      this.$router.push(route)
    },
    deleteGovernmentIdInIDB() {
      const objectStore = 'govermentId'
      initIndexedDB('memberProfile', objectStore, (db) => {
        const transaction = db.transaction([objectStore], 'readwrite')
        transaction.objectStore(objectStore).clear()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.government-id-success {
  padding-bottom: 40px;
  .page-title {
    font-size: 32px;
    line-height: 38px;
  }
  .central-column {
    padding: 12px 25px 0;
    .white-box {
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      border-radius: 12px;
      padding: 22px;
      font-size: 16px;
      line-height: 19px;
      .success-background {
        width: 125px;
        margin-top: 26px;
        margin-bottom: 50px;
      }
    }
    > .button {
      width: 100px;
      margin-top: 28px;
    }
  }
}
</style>
