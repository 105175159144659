import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * get balance
 * @param {String} country Canada or United States
 * @return {Promise}
 */
export const getBalance = (country = 'Canada') => {
  return axios.get(getImbyRoute('/api/v1/dashboard/balances'), { params: { country: country.replace(/\s/g, '_') } })
}

/**
 * bank account link status
 * @return {Promise}
 */
export const bankAccountLinkStatus = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/bank_account_link_status'))
}

/**
 * get property base url
 * @return {Promise}
 */
export const getPropertyBaseUrl = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/properties_for_sale_base_url'))
}

/**
 * get profile setup steps
 * @return {Promise}
 */
export const getProfileSteps = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/account_setup_steps'))
}

/**
 * get data for the recent investments banner
 * @return {Promise}
 */
export const recentInvestmentsBanner = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/recent_investments'))
}

/**
 * fetch blog data
 * @return {Promise}
 */
export const fetchBlogData = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/fetch_blog_data'))
}

/**
 * update blog view status
 * @param {Object} payload blog_title, action
 * @return {Promise}
 */
export const updateBlogFlags = (payload) => {
  return axios.post(getImbyRoute('/api/v1/dashboard/blog_post_activities'), { blog_post_activity: payload })
}

/**
 * Get Referral Flags
 * @return {Promise}
 */
export const getReferralFlags = () => {
  return axios.get(getImbyRoute('/api/v1/referral/flags'))
}

/**
 * Fetch for for-sale properties
 * @return {Promise}
 */
export const getActivePropFlag = () => {
  return axios.get(getImbyRoute('/api/v1/dashboard/sale_property'))
}
