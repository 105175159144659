<template>
  <div class="upload-government-id">
    <NavBack/>
    <div class="page-header sticky-header has-text-centered is-relative is-flex is-justify-content-center is-align-items-center">
      <div class="go-back" @click="goBack">
        <img src="@assets/icons/arrow-left.svg" alt="go-back">
      </div>
      <img class="logo" src="@assets/images/common/logo.png" alt="logo" @click="toDashboard">
    </div>
    <div class="page-title has-text-weight-semibold has-text-centered">Verify your account</div>
    <div class="main-body">
      <div class="white-box has-text-centered has-background-white">
        <div class="box-title has-text-weight-semibold">Your Government ID</div>
        <div class="text">Please submit a picture of your Government ID. This helps prevent someone else from creating an addy account in your name.</div>
      </div>
      <div class="upload is-flex is-justify-content-space-between is-align-items-center is-relative">
        <input type="file" name="government_id_image" @change="handleUpload" accept="image/*, application/pdf">
        <div class="left is-flex is-align-items-center">
          <div class="icon-background is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/icons/upload.svg" alt="upload">
          </div>
          <div class="item">Upload file</div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow-right" class="mr-4">
      </div>
      <div
        v-if="governmentIdStatus === 'failed'"
        class="reject-reasons">
        There was an issue with the previous government ID that you uploaded 😥 Please see the following {{reasons}}:
        <ul>
          <li v-for="(reason, index) in governmentIdRejectionReasons" :key="index">{{reason}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import { initIndexedDB } from '@utils/common-methods/initIndexedDB'
import { getProfileDetails } from '@api/profile'
import uploadIdMixin from '@utils/mixins/government-id-upload'

export default {
  mixins: [uploadIdMixin],
  components: {
    NavBack,
  },
  data() {
    return {
      governmentIdStatus: '',
      governmentIdRejectionReasons: [],
    }
  },
  created() {
    this.getData()
  },
  computed: {
    reasons() {
      return (this.governmentIdRejectionReasons && this.governmentIdRejectionReasons.length > 1) ? 'reasons' : 'reason'
    },
    nextStepRoute() {
      const returnUrl = this.$route.query.returnUrl
      const route = '/investorProfile/governmentId/verify'
      return returnUrl ? `${route}?returnUrl=${returnUrl}` : route
    },
  },
  methods: {
    getData() {
      getProfileDetails().then((data) => {
        if (!data.success) return
        const { governmentIdStatus, governmentIdRejectionReasons } = data.data
        this.governmentIdStatus = governmentIdStatus
        this.governmentIdRejectionReasons = governmentIdRejectionReasons
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    toDashboard() {
      this.$router.push('/dashboard')
    },
    handleUpload(event) {
      const fileType = event.target.files[0].type

      this.convertFileToArrayBuffer(event.target.files[0], (arrayBuffer) => {
        if (fileType !== 'application/pdf') return this.storeFileInIndexedDb(arrayBuffer)

        this.convertPdfToImageThenStore(arrayBuffer, (newArrayBuffer) => {
          this.storeFileInIndexedDb(newArrayBuffer)
        })
      })
    },
    storeFileInIndexedDb(arrayBuffer) {
      const objectStore = 'govermentId'
      initIndexedDB('memberProfile', objectStore, (db) => {
        const transaction = db.transaction([objectStore], 'readwrite')
        transaction.objectStore(objectStore).put(arrayBuffer, 'image').onsuccess = () => {
          this.$router.push(this.nextStepRoute)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-government-id {
  .page-header {
    height: 68px;
    .go-back {
      padding: 0 10px;
      position: absolute;
      top: 23px;
      left: 20px;
    }
    .logo {
      width: 63px;
    }
  }
  .page-title {
    font-size: 32px;
    line-height: 38px;
  }
  .main-body {
    padding: 12px 25px 0;
    .white-box {
      padding: 20px;
      height: 128px;
      border-radius: 12px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
      .box-title {
        font-size: 16px;
        padding-bottom: 8px;
      }
      .text {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .upload {
      margin-top: 30px;
      > input {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .icon-background {
        width: 40px;
        height: 40px;
        background-color: #F0EEF8;
        border-radius: 50%;
        margin-right: 14px;
        img {
          width: 22px;
        }
      }
      .item {
        font-size: 20px;
      }
    }
    .reject-reasons {
      margin-top: 22px;
      font-size: 12px;
      line-height: 14px;
      color: #C91717;
      ul {
        padding-left: 8px;
        li {
          font-size: 12px;
          line-height: 14px;
          color: #C91717;
          list-style: inside;
        }
      }
    }
  }
}
</style>
